import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import dateFormat from 'date-fns/format';
import {
  ADD_TO_BASKET,
  CLEAR_BASKET,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_RESPONSE,
  Order,
  OrderActionTypes,
  OrderLine,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_RESPONSE,
  FETCH_ORDERS_AMEND,
  FETCH_ORDERS_AMEND_REQUEST,
  SoftOrder,
} from '../types/Order';

import { RootState } from '../store/configureStore';
import { forceFloat, generateSessionID, getCustomerID, getUid, isAuthenticated, isRepOrder, setUid } from '../helpers/functions';
import { getOrderHistory, postSalesOrder } from '../api/OrderApi';
import history from '../helpers/history';
import CONSTANTS from '../helpers/constants';
import c from '../helpers/constants';
import { DeliveryAddress } from '../types/Customer';
import { compact } from 'lodash';
import { toast } from 'react-toastify';
import { config } from '../config/config';


export function sanitizeReference(value: string) {
  value = value.replace(/`/g, "'");
  let sanitizedValue = value.replace(/[^0-9a-zA-Z\s']/g, '');
  return sanitizedValue;
};

export function addToBasketRequest(softOrder: SoftOrder): OrderActionTypes {
  return {
    type: ADD_TO_BASKET,
    payload: softOrder,
  };
}

export function clearBasketRequest(): OrderActionTypes {
  return {
    type: CLEAR_BASKET,
    payload: null,
  };
}


export function fetchOrdersRequest(): OrderActionTypes {
  return {
    type: FETCH_ORDERS_REQUEST,
    payload: null,
  };
}

export function fetchOrdersAmendmentRequest(): OrderActionTypes {
  return {
    type: FETCH_ORDERS_AMEND_REQUEST,
    payload: null,
  };
}


export function fetchOrdersResponse(
  orders: Order[],
): OrderActionTypes {
  return {
    type: FETCH_ORDERS_RESPONSE,
    payload: orders,
  };
}

export function fetchOrdersAmendResponse(
  orders: Order[],
): OrderActionTypes {
  return {
    type: FETCH_ORDERS_AMEND,
    payload: orders,
  };
}
export function fetchOrdersFailure(): OrderActionTypes {
  return {
    type: FETCH_ORDERS_FAILURE,
    payload: null,
  };
}

export function placeOrdersRequest(): OrderActionTypes {
  return {
    type: PLACE_ORDER_REQUEST,
    payload: null,
  };
}

export function placeOrdersResponse(): OrderActionTypes {
  return {
    type: PLACE_ORDER_RESPONSE,
    payload: null,
  };
}

export function placeOrdersFailure(): OrderActionTypes {
  return {
    type: PLACE_ORDER_FAILURE,
    payload: null,
  };
}

export const addToBasketCutsRolls = (orderLines: OrderLine[], delivery_address_code?: string):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {

    const existingLines = getState().order.basket;
    const total = config.postFullBasket ? (existingLines ? existingLines.concat([...orderLines]) : [...orderLines]) : [...orderLines];


    const data = {
      orderlines: total.map(o => ({
        uid: o.uid,
        product_sku: o.product_sku,
        quantity: o.quantity,
        length: forceFloat(o.length),
        width: forceFloat(o.width),
        pallet_id: o.pallet_id,
        line_reference: sanitizeReference(o.line_reference),
        line_price: o.item_price,
        line_discount: forceFloat(o.line_discount),
        roll_id: o.roll_id,
        half_roll: o.half_roll,
        line_delivery_date: o.line_delivery_date,
        collection: o.collection,
        is_presold: o.presold,
      })),
      order_type: 'Soft',
      customer_id: getCustomerID(),
      delivery_date: dateFormat(Date.now(), 'dd/MM/yy'),
      customer_delivery_id: null != delivery_address_code && delivery_address_code.length > 0 ? delivery_address_code : '01',
      special_instructions: 'none',
      order_reference: 'Ref',
      order_uid: getUid()
    };

    const result = await postSalesOrder(data);

    let continueWithAdding = true;

    let excludedCodes = ['OK', '202', '200', 'STK NCM', 'NC'];

    if (excludedCodes.includes(result.return_code) == false) {
      continueWithAdding = false;
      toast.error('Sorry! There was an error adding this product to your cart', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (result.return_code == 'STK NCM' || result.return_code == 'NC') {
      toast.warn(result.return_desc, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const collectDates = result?.collect_date?.map( (o: any) => dateFormat(new Date(o.delivery_date), 'dd/MM/yy'));
    const deliveryDates = result?.delivery_date?.map((o: any) => dateFormat(new Date(o.delivery_date), 'dd/MM/yy'));
    const deliveryCharge = result?.delivery_charge ?? 0;

    let x = result?.return_lines;

    //Retrieve the po dates 
    if (x) {
      for (let i = 0; i < x.length; i++) {
        let y = x[i];
        orderLines.forEach((z: any) => {
          if (z.product_sku == y.product_sku && z.quantity == y.quantity && z.LENGTH == y.length && z.WIDTH == y.width && z.line_reference == y.line_reference && y.poduedate != null) {
            if (!z?.po_dates) {
              z.po_dates = [];
            }
            if (y.poduedatelist != null) {
              for (let j = 0; j < y.poduedatelist.length; j++) {
                if (y.poduedatelist[j] != null) {
                  z.po_dates.push(dateFormat(new Date(y.poduedatelist[j]), 'dd/MM/yy'));
                }
              }
            }
          }
        })
      };
    }

    const { return_code, return_desc, return_lines } = result;
    let finalOrderLines: any[] = [];
    if (return_code === 'OK' || continueWithAdding) {
      finalOrderLines = orderLines;
    }

    dispatch(addToBasketRequest({
      deliveryCharge: deliveryCharge,
      orderLines: finalOrderLines,
      deliveryDates,
      return_code,
      return_desc,
      return_lines,
      collectDates
    }));
  };


export const addToBasket = (orderLines: OrderLine[], delivery_address_code?: string, deleteCall: boolean = false, ignoreApiUpdate: boolean = false):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    let deliveryCharge = 0;

    const existingLines = getState().order.basket;
    const total = config.postFullBasket ? (existingLines ? existingLines.concat([...orderLines]) : [...orderLines]) : [...orderLines];

    const data = {
      orderlines: total.map(o => ({
        uid: o.uid,
        product_sku: o.product_sku,
        quantity: o.quantity,
        length: forceFloat(o.length),
        width: forceFloat(o.width),
        pallet_id: o.pallet_id,
        line_reference: sanitizeReference(o.line_reference),
        line_price: o.item_price,
        line_discount: forceFloat(o.line_discount),
        roll_id: o.roll_id,
        half_roll: o.half_roll,
        line_delivery_date: o.line_delivery_date,
        collection: o.collection,
        is_presold: o.presold,
        po_dates: []
      })),
      order_type: 'Soft',
      customer_id: getCustomerID(),
      delivery_date: dateFormat(Date.now(), 'dd/MM/yy'),
      customer_delivery_id: delivery_address_code != null && delivery_address_code.length > 0 ? delivery_address_code : '01',
      special_instructions: 'none',
      order_reference: 'Ref',
      order_uid: getUid()
    };


    let deliveryDates: string[] = getState().order.deliveryDates;
    let actualDeliveryCharge: number = getState().order.deliveryCharge;
    let collectDates = getState().order.collectDates;


    if (!ignoreApiUpdate) {
      const result = await postSalesOrder(data);
      deliveryDates = result?.delivery_date?.map((o: any) => dateFormat(new Date(o.delivery_date), 'dd/MM/yy'));
      deliveryCharge = result?.delivery_charge ?? 0;
      
      collectDates = result?.collect_date?.map((o: any) => dateFormat(new Date(o.delivery_date), 'dd/MM/yy'));




      let continueWithAdding = true;

      let excludedCodes = ['OK', '202', '200', 'STK NCM', 'NC'];

      if (excludedCodes.includes(result.return_code) == false) {
        continueWithAdding = false;
        toast.error('Sorry! There was an error adding this product to your cart', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      if (result.return_code == 'STK NCM' || result.return_code == 'NC') {
        toast.warn(result.return_desc, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


      let x = result?.return_lines;

      //Retrieve the po dates 
      if (x) {
        for (let i = 0; i < x.length; i++) {
          let y = x[i];
          orderLines.forEach((z: any) => {
            if (z.product_sku == y.product_sku && z.quantity == y.quantity && z.LENGTH == y.length && z.WIDTH == y.width && z.line_reference == y.line_reference && y.poduedate != null) {
              if (!z?.po_dates) {
                z.po_dates = [];
              }
              if (y.poduedatelist != null) {
                for (let j = 0; j < y.poduedatelist.length; j++) {
                  if (y.poduedatelist[j] != null) {
                    z.po_dates.push(dateFormat(new Date(y.poduedatelist[j]), 'dd/MM/yy'));
                  }
                }
              }
            }
          })
        };
      }

      dispatch(addToBasketRequest({ orderLines, deliveryDates, deliveryCharge, collectDates }));
      return;
    }

    deliveryCharge = actualDeliveryCharge;
    dispatch(addToBasketRequest({ orderLines, deliveryDates, deliveryCharge, collectDates }));
  };



export const clearBasket = ():
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(clearBasketRequest());
  };

export const placeOrder = (deliveryDate: string, deliveryCode: string, deliveryAddress: DeliveryAddress | null, collection: boolean | null, specialRequest: string | null, customerRef?: string | null):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const orderLines = getState().order.basket;
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    dispatch(placeOrdersRequest());

    let isRep = isRepOrder(); 

    let data = {
      orderlines: orderLines.map(o => ({
        uid: o.uid,
        product_sku: o.product_sku,
        quantity: o.quantity,
        length: forceFloat(o.length),
        width: forceFloat(o.width),
        pallet_id: o.pallet_id,
        line_reference: sanitizeReference(o.line_reference),
        line_price: o.item_price,
        line_discount: forceFloat(o.line_discount),
        roll_id: o.roll_id,
        half_roll: o.half_roll,
        line_delivery_date: o.line_delivery_date,
        collection: o.collection,
        is_presold: o.presold,
      })),
      order_type: 'Hard',
      customer_id: getCustomerID(),
      customer_delivery_id: deliveryCode,
      delivery_date: deliveryDate,
      special_instructions: specialRequest && specialRequest != '' ? specialRequest : 'none',
      order_reference: customerRef != null ? customerRef : 'Ref',
      del_name: '',
      del_addr1: '',
      del_addr2: '',
      del_town: '',
      del_county: '',
      del_pcode: '',
      collection: collection && collection,
      source: isRep ? config.source + '-rep' : config.source,
      order_uid: getUid()
    };


    if (deliveryAddress != null) {
      data.del_name = deliveryAddress.NAME;
      data.del_addr1 = deliveryAddress.address_1;
      data.del_addr2 = deliveryAddress.address_2;
      data.del_town = deliveryAddress.town;
      data.del_county = deliveryAddress.county;
      data.del_pcode = deliveryAddress.pcode;
    }


    const asyncResp = await postSalesOrder(data);
    if (asyncResp.return_code === 'OK') {
      dispatch(placeOrdersResponse());
      dispatch(clearBasketRequest());
      // @ts-ignore
      history.push({
        pathname: CONSTANTS.APP_ROUTES.ORDER_CONFIRMATION,
        search: `orderNumber=${asyncResp?.order_number}`,
      });
      let uid = generateSessionID();
      setUid(uid);
    } else {
      dispatch(placeOrdersFailure());
    }
  };

export const fetchOrders = ():
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOrdersRequest());
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    const asyncResp: any = await getOrderHistory(getCustomerID());
    if (asyncResp.return_code === 'OK') {
      await dispatch(fetchOrdersResponse(asyncResp.order_lines));
    } else {
      await dispatch(fetchOrdersFailure());
    }
  };
  export const fetchOrdersByDate = (dateFrom: string, dateTo: string):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOrdersRequest());
    if (!isAuthenticated()) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    const asyncResp: any = await getOrderHistory(getCustomerID(), undefined, dateFrom, dateTo);
    if (asyncResp.return_code === 'OK') {
      console.log('fetchOrdersByDate', asyncResp.order_lines);
      await dispatch(fetchOrdersAmendResponse(asyncResp.order_lines));
    } else {
      await dispatch(fetchOrdersFailure());
    }
  };
