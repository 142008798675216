import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import dateFormat from 'date-fns/format';
import {
  FETCH_CUSTOMER_FAILURE,
  FETCH_CUSTOMER_RESPONSE,
  FETCH_CUSTOMER_REQUEST,
  Customer,
  CustomerActionTypes,
} from '../types/Customer';


import { RootState } from '../store/configureStore';
import {
  digestMessage,
  getCustomerID,
  getCustomerPostcode,
  setApiKey,
  setCustomerBranch,
  setCustomerID,
  setCustomerPostcode,
  setCustomerStatus,
  setOrderSource,
} from '../helpers/functions';
import { doLogin, getCustomerDeliveryAddresses, getCustomerDetails } from '../api/CustomerApi';
import history from '../helpers/history';
import c from '../helpers/constants';
import { AvailableType, FETCH_PRODUCT_AVAILABLE_TYPES_RESPONSE, ProductAvailableTypeAction } from '../types/AvailableType';
import { clearBasketRequest } from './Order';

export function fetchCustomerRequest(): CustomerActionTypes {
  return {
    type: FETCH_CUSTOMER_REQUEST,
    payload: null,
  };
}

export function fetchCustomerResponse(
  customer: Customer,
): CustomerActionTypes {
  return {
    type: FETCH_CUSTOMER_RESPONSE,
    payload: customer,
  };
}


export function fetchAvailableTypesResponse(
  productAvailableTypes: AvailableType[],
): ProductAvailableTypeAction {
  return {
    type: FETCH_PRODUCT_AVAILABLE_TYPES_RESPONSE,
    payload: productAvailableTypes,
  };
}

export function fetchCustomerFailure(): CustomerActionTypes {
  return {
    type: FETCH_CUSTOMER_FAILURE,
    payload: null,
  };
}

export const fetchCustomer = (customerId?: string, postcode?: string, login: boolean = false):
  ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCustomerRequest());
    const now = new Date;
    const utcTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
      now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
    const formattedDate = dateFormat(utcTimestamp, 'dd/MM/yy');
    const finalPostcode = postcode || getCustomerPostcode() || '';
    const finalCustomerId = customerId || getCustomerID() || '';
    if (!finalCustomerId || !finalPostcode) {
      history.push(c.APP_ROUTES.LOGIN);
    }
    const customerKey = await digestMessage(`${finalPostcode}${formattedDate}`);
    const asyncResp: any = (login == true ? await doLogin(finalCustomerId, customerKey) : await getCustomerDetails(finalCustomerId, customerKey));
    const asyncRespAddr: any = await getCustomerDeliveryAddresses(finalCustomerId);

    if (asyncResp?.return_code === 'OK' && asyncRespAddr) {

      //Set the api key 
      if (login == true) {
        let token = asyncResp?.token;
        setApiKey(token);
      }

      //get matting customer - not really an ideal solution  
      let cust = { ...asyncResp?.dsCustomerDetails?.['op-tt-customer']?.[0] };
      cust.is_matting = cust.branch_name.toLowerCase() == 'likewise matting';

      //Set the branch up 
      setCustomerBranch('FFD');
      setCustomerStatus( cust?.payment_terms_code );
    
      if (cust?.which_passwd) {
        setOrderSource(cust?.which_passwd);
      } else {
        setOrderSource('');
      }

      const obj: Customer = {
        ...cust,
        delivery_addresses: asyncRespAddr?.dsCustomerDeliveryAddresses?.['op-tt-addresses'],
      };

      let types: AvailableType[] = [];
      asyncResp?.product_types.map((x: any) => {
        types.push(x as AvailableType);
      });

      await dispatch(fetchCustomerResponse(obj));
      await dispatch(fetchAvailableTypesResponse(types));
      setCustomerPostcode(finalPostcode);
      setCustomerID(finalCustomerId);
    } else {
      clearBasketRequest();
      setCustomerID('');
      setCustomerPostcode('');
      await dispatch(fetchCustomerFailure());
    }
  };
