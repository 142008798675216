import React, { useEffect, useState } from 'react';
import '../styles/MyStatements.scss';
import View from '../components/View';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomer } from '../actions/Customer';
import { calculateOutstanding, formatMayneDate, getCustomerID, getXDaysAgo, useQuery } from '../helpers/functions';
import DropDown from '../components/DropDown';
import MyStatementsBar from '../components/MyStatementsBar';
import { CheckSquare, CheckSquareBlank, DownloadIcon, EllipsisIcon } from '../helpers/icons';
import ContextMenu from '../components/ContextMenu';
import PaymentModal from '../components/PaymentModal';
import { config } from '../config/config';
import LwBankModal from '../components/LwBankModal';
import SelectBank from '../components/SelectBank';
import { getInvoice } from '../actions/AvailableBanks';
import { toast } from 'react-toastify';
import { InvoiceDefinition } from '../renderer/invoice';
import { getInvoices } from '../selectors/Invoice';
import { fetchInvoices } from '../actions/Invoices';
import chevronLeft from './../assets/svgs/chevron_left.svg';
import chevronRight from './../assets/svgs/chevron_right.svg';
import _ from 'lodash';
import { fetchSummary } from '../actions/Summary';
import { getSummary } from '../selectors/Summary';

declare let window: any;


const MyStatements = () => {
    const query = useQuery();
    const dispatch = useDispatch();
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [selectedTab, changeSelection] = useState<string>('outstanding');
    const [show, setShow] = useState<boolean>(false);
    const [x, setX] = useState<number>(0);
    const [y, setY] = useState<number>(0);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showNumberInput, setShowNumberInput] = useState<boolean>(false);
    const [showBankModal, setShowBankModal] = useState<boolean>(false);
    const enablePayments = config.hasOwnProperty('enablePayments') ? config.enablePayments : false;
    const [paymentData, setPaymentData] = useState<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pageIndex, setPageIndex] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showFirst, setShowFirst] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showPrevious, setShowPrevious] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showNext, setShowNext] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showLast, setShowLast] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pageCount, setPageCount] = useState(0);
    const [pageRange, setPageRange] = useState<number[]>([]);
    const [productsPerPage, setProductsPerPage] = useState(25);

    const summary = useSelector(getSummary);
    const data = useSelector(getInvoices);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [actualInvoices, setActualInvoices] = useState<any[]>([]);



    const filterOptions = [
        {
            label: 'Due Invoices',
            value: 'outstanding'
        }, {

            label: 'All Invoices',
            value: 'all'
        },
        {
            label: 'Overdue Invoices',
            value: 'overdue'
        },
        {
            label: 'Paid Invoices',
            value: 'paid'
        }];

    let total = 0;

    const balance = 0;
    const accountBalance = summary?.account_balance ?? 0;



    useEffect(() => {
        dispatch(fetchInvoices());
        dispatch(fetchSummary());
    }, [dispatch]);




    useEffect(() => {
        data.reverse();
        collateInvoices();
    }, [data]);

    const collateInvoices = () => {
        let aInv: any = [];
        if (!data || data.length == 0) {
            aInv = [];
            return;
        }
        let idxs: number[] = [];
        data.forEach(x => {
            let theIdx = idxs.indexOf(x.ownref);

            if (theIdx < 0) {
                idxs.push(x.ownref);
                aInv.push({ ...x });
                return;
            }

            aInv[theIdx].fgoods += x.fgoods;
            aInv[theIdx].fvat += x.fvat;
            aInv[theIdx].fpaid += x.fpaid;
        });
        setActualInvoices(aInv);
    }






    const handleChildEvent = async (childOption: any) => {
        setShow(false);
        if (null != childOption) {
            //Do the action 
            if (childOption.action == 'download') {
                let o = data.filter((x: any) => x.ownref == selectedOption);
                await navigateToInvoice(selectedOption, o[0].trnno);
            }
            setSelectedOption('');
        }
    };


    const doClickAction = (type: string) => {
        if (type == 'any') {
            setShowNumberInput(true);
        } else {
            setShowNumberInput(false);
        }
        setShowModal(true);
    };

    const toggleCheckAll = () => {
        if (checkedAll) {
            setCheckedItems([]);
        } else {
            //Get the active slice
            let d = _.slice(filteredData, pageIndex * productsPerPage, (pageIndex * productsPerPage) + productsPerPage);

            let x = d?.filter((i: any) => calculateOutstanding(i) != '0.00');
            setCheckedItems(x.map((item: any) => item.ownref));
        }
        setCheckedAll(!checkedAll);
    };

    const isToggled = (invoiceNo: string) => {
        let idx = checkedItems.indexOf(invoiceNo);
        if (idx > -1) {
            return true;
        }
        return false;
    };

    const toggleItem = (invoiceNo: string, outstanding: string) => {
        let idx = checkedItems.indexOf(invoiceNo);
        let items = [];

        //Can't toggle it as it's fully paid  
        if (outstanding == '0.00') {
            return;
        }

        if (idx > -1) {
            checkedItems.splice(idx, 1);
        } else {
            checkedItems.push(invoiceNo);
        }
        items = [...checkedItems];
        setCheckedItems(items);
    };

    const [filter, setFilter] = useState<any>(filterOptions[0]);

    const navigateToInvoice = async (invoiceNo: any, trnno: any) => {
        let inv = await getInvoice(invoiceNo, trnno);

        if (!inv) {
            toast.error('An error occurred loading your invoice', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        let definition = new InvoiceDefinition();
        await definition.generate(inv, invoiceNo);



    };



    const showMenu = (e: any, orderNumber: string) => {
        setX(e.pageX);
        setY(e.pageY);
        setShow(true);
        setSelectedOption(orderNumber)
    }

    const onPaymentAction = (data: any) => {

        setShowModal(false);
        setPaymentData(data);
        setShowBankModal(true);
    };

    const menuOptions: any[] = [
        { text: 'Download Invoice', action: 'download', icon: DownloadIcon() }
    ];

    let checkedTotal = data
        .filter(x => checkedItems.indexOf(x.ownref as string) > -1)
        .map(y => parseFloat((calculateOutstanding(y) as string).replace('£', '').replace(',', ''))) // Remove '£' and convert to number
        .reduce((acc, val) => acc + val, 0); // Sum up the amounts



    useEffect(() => {
        if (!actualInvoices) return;

        let filtered: any[] = [];
        if (filter.value === 'all') {
            filtered = [...actualInvoices];
        } else if (filter.value === 'outstanding') {
            filtered = [...actualInvoices.filter(x => calculateOutstanding(x) !== '0.00' && calculateOutstanding(x) !== '-0.00')];
        } else if (filter.value === 'paid') {
            filtered = [...actualInvoices.filter(x => calculateOutstanding(x) === '0.00')];
        } else if (filter.value === 'overdue') {
            filtered = [...actualInvoices.filter(x => calculateOutstanding(x) !== '0.00' && x.overdue === true)];
        }

        const lastDate = selectedTab === '30' || selectedTab === '60' || selectedTab === '90' ? parseInt(selectedTab) : 0;
        const theDate = getXDaysAgo(lastDate, true);

        if (lastDate !== 0) {
            filtered = filtered.filter(x => x.ddate >= theDate);
        }

        setFilteredData(filtered);

        // Update the page range based on the new filtered data
        applyPageRangeLogic(0); // Start at the first page after filtering
        setPageIndex(0); // Reset page index

    }, [filter, actualInvoices, selectedTab, productsPerPage]);


    const applyPageRangeLogic = (currIdx: number) => {
        //Total pages
        let localPageCount = Math.ceil(filteredData?.length / productsPerPage);

        //Show 5 pages or less if there are fewer
        let pagesToShow: number = localPageCount < 5 ? localPageCount : 5;

        //If there are fewer resolve it now and show all
        let start = 0;
        let end = pagesToShow;
        if (localPageCount > 5) {
            //Get a sliding window of x items
            start = (currIdx - (Math.floor(pagesToShow / 2)));
            start = start < 0 ? 0 : start;
            end = start + pagesToShow;


            if (end > localPageCount) {
                start = start > 0 ? start - 1 : start;
                end = localPageCount;
            }
        }
        setPageRange(_.range(start, end));
    };

    useEffect(() => {
        applyPageRangeLogic(pageIndex);
        //console.log('two');
    }, [filteredData, pageIndex]);


    const onClickIndex = (goToIndex: number) => {

        // const first = _.first(pageRange) || 0;
        let localPageCount = Math.ceil(filteredData?.length / productsPerPage);
        applyPageRangeLogic(goToIndex - 1);
        setPageIndex(goToIndex);
        setShowPrevious(goToIndex > 0);
        setShowFirst(goToIndex > 0);
        setShowNext(goToIndex + 1 < localPageCount);
        setShowLast(goToIndex + 1 < localPageCount);


    };

    const onNext = () => {
        if (!showNext) return;
        let currIdx = pageIndex + 1;
        let localPageCount = Math.ceil(filteredData?.length / productsPerPage);


        applyPageRangeLogic(currIdx);
        setShowPrevious(true);
        setShowFirst(true);
        setShowNext(currIdx + 1 < localPageCount);
        setShowLast(currIdx + 1 < localPageCount);
        setPageIndex(pageIndex + 1);
    };


    const onPrevious = async () => {
        if (!showPrevious) return;
        let currIdx = pageIndex - 1;

        let localPageCount = Math.ceil(filteredData?.length / productsPerPage);
        applyPageRangeLogic(currIdx);

        setShowFirst(pageIndex > 1);
        setShowPrevious(pageIndex > 1);
        setShowNext(pageIndex < localPageCount);
        setShowLast(pageIndex < localPageCount);
        setPageIndex(pageIndex - 1);
    };

    const onFirst = () => {
        if (!showFirst) return;
        applyPageRangeLogic(0);
        setPageIndex(0);
        setShowPrevious(false);
        setShowFirst(false);
        setShowNext(true);
        setShowLast(true);
    };

    const onLast = () => {
        if (!showLast) return;

        let localPageCount = Math.floor(filteredData?.length / productsPerPage);

        applyPageRangeLogic(localPageCount - 1);
        setPageIndex(localPageCount - 1);
        setShowPrevious(true);
        setShowFirst(true);
        setShowNext(false);
        setShowLast(false);
    };


    useEffect(() => {
        dispatch(fetchCustomer());
    }, [dispatch]);




    total = filteredData?.length;

    let invoiceTotal = 0;
    let outstandingTotal = 0;


    if (filteredData) {
        let paidTotal = 0;
        filteredData.forEach(x => {
            invoiceTotal += parseFloat(x.fgoods) + parseFloat(x.fvat);
            paidTotal += parseFloat(x.fpaid);
        });

        outstandingTotal = parseFloat(invoiceTotal.toFixed(2)) - paidTotal;
    }


    const renderPaging = () => (filteredData?.length > 0 && pageRange.length > 0) && <div className="row gy-5 search-row pb-5">

        <div className="lw-pagination">
            {config.pagination.showFirst &&
                <div className={`lw-pagination__control ${!showFirst ? 'disabled' : ''}`} onClick={onFirst}> First </div>
            }
            <div className={`lw-pagination__control ${!showPrevious ? 'disabled' : ''}`} onClick={onPrevious}> {
                config.pagination.prevIcon && <><img src={chevronLeft} /></>
            }
                {
                    !config.pagination.prevIcon && <>Previous</>
                }
            </div>
            {pageRange.map((index) => (
                <div
                    onClick={() => onClickIndex(index)}
                    className={`lw-pagination__pill ${pageIndex === index ? 'active-pill' : ''}`}
                    key={index}>
                    {index + 1}
                </div>
            ))}
            <div className={`lw-pagination__control ${!showNext ? 'disabled' : ''}`} onClick={onNext}> {
                config.pagination.nextIcon && <><img src={chevronRight} /></>
            }
                {
                    !config.pagination.nextIcon && <>Next</>
                } </div>
            {config.pagination.showLast &&
                <div className={`lw-pagination__control ${!showLast ? 'disabled' : ''}`} onClick={onLast}> Last </div>
            }
        </div>
    </div>;



    const uniqueById = new Set(checkedItems).size;


    return (
        <View>
            <div className="-container">
                <div className="container my-statements">

                    <div className='my-statements-header'>
                        <div className='d-flex justify-content-between align-items-center mt-3'>
                            <div><h3>My Statements</h3></div>
                            <div><div className='account-balance'>Account Balance : &pound;{accountBalance.toFixed(2)}</div></div>
                        </div>
                    </div>


                    <MyStatementsBar total={checkedTotal} numberOfInvoices={uniqueById} clickEvent={doClickAction}></MyStatementsBar>



                    <div className='tab-row d-flex justify-content-between align-items-center'>
                        <div className='tabs-left'>
                            <ul>
                                <li>
                                    <a className={selectedTab === 'outstanding' ? 'selected' : 'text-grey'} onClick={() => changeSelection('outstanding')}>Outstanding</a>
                                </li>
                                <li>
                                    <a className={selectedTab === 'overdue' ? 'selected' : 'text-grey'} onClick={() => changeSelection('Overdue')}>Overdue</a>
                                </li>
                            </ul>
                        </div>
                        <div className='tabs-right'>
                            <DropDown items={filterOptions} value={filter?.value}

                                onSelect={(item) => {
                                    setFilter(item);
                                }} />
                        </div>
                    </div>

                    <div className='payments-table'>
                        <table>
                            <thead>
                                <tr>
                                    <th className='checkbox-col'>
                                        <span className={checkedAll ? 'cursor fill-square checkbox' : 'checkbox cursor'} onClick={() => toggleCheckAll()}>
                                            {
                                                checkedAll ?
                                                    CheckSquare() :
                                                    CheckSquareBlank()
                                            }
                                        </span>
                                    </th>
                                    <th>Invoice No.</th>
                                    <th>Reference</th>
                                    <th>Due</th>
                                    <th>Amount</th>
                                    <th>Outstanding</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(!filteredData || filteredData.length == 0) &&

                                    <td colSpan={7}>
                                        <div className='alert alert-warning mt-2 mb-2'>
                                            There are no invoices
                                        </div>
                                    </td>
                                }

                                {(filteredData && filteredData != null) && (_.slice(filteredData, pageIndex * productsPerPage, (pageIndex * productsPerPage) + productsPerPage))?.map((s, i) =>
                                    <tr>
                                        <td className='checkbox-col'>
                                            <span className={isToggled(s.ownref as string) ? 'cursor fill-square checkbox' : 'checkbox cursor'} onClick={() => toggleItem(s.ownref as string, calculateOutstanding(s))}>

                                                {
                                                    isToggled(s.ownref as string) ?
                                                        CheckSquare() :
                                                        CheckSquareBlank()
                                                }
                                            </span>
                                        </td>
                                        <td><a onClick={() => navigateToInvoice(s.ownref, s.trnno)}>{s.ownref}</a></td>
                                        <td>Ref:  {s.ownref}</td>
                                        <td>{formatMayneDate(s.ddate)}</td>
                                        <td>&pound;{(s.fgoods + s.fvat).toFixed(2)}</td>
                                        <td>&pound;{calculateOutstanding(s)}</td>
                                        <td>
                                            <a className='cursor ellipsis' onClick={(evt: any) => showMenu(evt, s.ownref as string)}>{
                                                EllipsisIcon()
                                            }</a>
                                        </td>
                                    </tr>
                                )
                                }

                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3}>
                                        {total} Invoice{total == 1 ? '' : 's'}
                                    </td>
                                    <td>
                                        Total
                                    </td>
                                    <td>
                                        £{invoiceTotal.toFixed(2)}
                                    </td>
                                    <td>
                                        £{outstandingTotal.toFixed(2)}
                                    </td>
                                    <td></td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>

                    <div className='paging'>
                        {renderPaging()}
                    </div>

                    <div className='pb-4'>
                        <MyStatementsBar total={checkedTotal} numberOfInvoices={uniqueById} clickEvent={doClickAction}></MyStatementsBar>
                    </div>
                </div>
            </div>


            <LwBankModal title="" byText='' onClose={() => {
                setShowModal(false);
            }}
                show={showModal}>
                <>
                    <PaymentModal checkedItems={checkedItems} data={filteredData} showNumberInput={showNumberInput} balance={accountBalance} doPaymentAction={onPaymentAction}></PaymentModal>
                </>
            </LwBankModal>


            <LwBankModal title="Select Your Bank" byText='by Natwest' onClose={() => {
                setShowBankModal(false);
            }}
                show={showBankModal}>
                <SelectBank type='statement' data={paymentData} orderValue={paymentData?.value}></SelectBank>
            </LwBankModal>





            <ContextMenu x={x} y={y} show={show} options={menuOptions} clickEvent={handleChildEvent}></ContextMenu>
        </View>
    );
};

export default MyStatements;
