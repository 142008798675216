import React, { useEffect, useState } from 'react';
import '../styles/OrderConfirmation.scss';
import View from '../components/View';
import _ from 'lodash';
import CardCheckout from '../components/CardCheckout';
import MobileMenuBottom from '../components/MobileBottomMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../selectors/Order';
import { fetchOrders } from '../actions/Order';
import { getCustomerStatus, useQuery } from '../helpers/functions';
import dateFormat from 'date-fns/format';
import LWModal from '../components/Modal';
import SelectBank from '../components/SelectBank';
import { payOrder } from '../actions/AvailableBanks';
import Button from '../components/Button';
import LwBankModal from '../components/LwBankModal';
import { config } from '../config/config';


const OrderConfirmation = () => {
  const orders = useSelector(getOrders);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);
  const query = useQuery();
  const [preToggle, setPreToggle] = useState(false);

  const [toggleTimeoutTracker, setToggleTimeoutTracker] = useState<any>(null);

  const [toggle, setToggle] = useState(false);
  const orderNumber = parseInt(query.get('orderNumber') || '0', 10);
  const order = orders?.filter(o => o.order_number === orderNumber);
  const first = _.first(order);
  const [autopopup, setAutoPopup] = useState<boolean>(false);

  const enablePayments = config.hasOwnProperty('enablePayments') ? config.enablePayments : false;



  const triggerPayment = () => {
    if (toggleTimeoutTracker) {
      clearTimeout(toggleTimeoutTracker);
      setToggleTimeoutTracker(null);
    }
    setPreToggle(!preToggle);
    setToggleTimeout();
  };


  const customerStatus = getCustomerStatus();

  const setToggleTimeout = () => {
    let c = setTimeout(() => {
      setPreToggle(false);
      setToggle(true);
    }, config.payit.popupSeconds * 1000);

    setToggleTimeoutTracker(c);
  };



  useEffect(() => {
    if (
      customerStatus === 'P' &&
      toggle === false &&
      order &&
      order[0]?.order_status === 'On Hold' &&
      !autopopup
    ) {
      const timeout = setTimeout(() => {
        setPreToggle(true);
        setAutoPopup(true);
        setToggleTimeout();
      }, config.payit.popupSeconds * 1000); // 2-3 seconds delay

      setToggleTimeoutTracker(toggleTimeoutTracker);

      return () => {
        if (toggleTimeoutTracker) {
          clearTimeout(toggleTimeoutTracker);
          setToggleTimeoutTracker(null);
        }
        clearTimeout(timeout); // Cleanup timeout on unmount
      }
    }
  }, [customerStatus, toggle, order, autopopup]);


  let subtotal = 0;
  let vat = 0;
  order?.forEach(o => {
    subtotal += o.line_value;
    vat += o.line_vat_value;
  });
  return (
    <View>
      <div className="confirmation">
        <div className="container">
          <div className="confirmation__title">
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <h4>Order Confirmed</h4>
              </div>


            </div>


          </div>
          <div className="row confirmation__info pb-5 d-flex">
            <div className="col-lg-2 col-md-12 confirmation__info__id">
              <p className="medium-grey-text pb-3">Order Number</p>
              <p className="medium-grey-text pb-3">{orderNumber}</p>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 confirmation__info__datet pb-3">
              <p className="medium-grey-text pb-3">Order Date</p>
              <p>{dateFormat(new Date(first?.date_ordered || '2022-01-01'), 'do MMM yyy')}</p>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 confirmation__info__datet pb-3">
              <p className="medium-grey-text pb-3">Scheduled Delivery Date</p>
              <p>{dateFormat(new Date(first?.delivery_date || '2022-01-01'), 'do MMM yyy')}</p>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 confirmation__info__total pb-3">
              <p className="medium-grey-text pb-3">Order Total</p>
              <p>£{(subtotal + vat).toFixed(2)}</p>
            </div>
            {
              (enablePayments && (subtotal + vat > 0) && (customerStatus == 'P' || customerStatus == 'COD')) &&
              <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 confirmation__info__total pb-3">
                <p className="medium-grey-text pb-3 square-button">Status On Hold</p>
                {
                  <Button onClick={() => triggerPayment()} text="Pay Order" size={"sm"} shape={"square"} />
                }
              </div>
            }
          </div>
          <div className="confirmation__line mb-5"></div>
          <div className="confirmation-order pb-3">
            <p>Your Order</p>
          </div>
          {order.map((o, i) => <CardCheckout
            key={i}
            lineNo={i}
            imageCode={o.product_sku}
            quality={o.product_desc}
            category={o.order_status}
            categoryCode={o.product_type_code}
            linePrice={o.line_value}
            itemPrice={o.line_price}
            length={o.LENGTH}
            width={o.WIDTH}
            quantity={o.qty_ordered}
            hideAction
            reference={o.line_reference}
            deliveryDate={o.delivery_date}
            sqmPrice={o.price_per_sqm || 0}
            collection={o.collection || false}
          />)}
        </div>
        <div className="container">
          <div className="row">
            <div className='col-lg-8'>

            </div>
            <div className="order-total col-lg-4">
              <h4>Order Total</h4>
              <div className="order-total__column">
                <div className="order-total__column__subtotal d-flex pb-3">
                  <p>Subtotal Excl VAT</p>
                  <p>£{(subtotal).toFixed(2)}</p>
                </div>
                <div className="order-total__column__vat d-flex pb-3">
                  <p>VAT (20%)</p>
                  <p>£{(vat).toFixed(2)}</p>
                </div>
                <div className="order-total__column__total d-flex pb-3">
                  <p>Total</p>
                  <p>£{(subtotal + vat).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-mobile-nav">
        <MobileMenuBottom />
      </div>


      {
        (enablePayments && (subtotal + vat > 0)) && <>
          <>

            <LwBankModal title="Select Your Bank" byText='by Natwest' onClose={() => {
              setPreToggle(false);
            }}
              show={preToggle}>
              <div className='loading-modal'>{config.payit.loadingMessage}</div>
            </LwBankModal>

            <LwBankModal title="Select Your Bank" byText='by Natwest' onClose={() => {
              setToggle(false);
            }}
              show={toggle}>
              <SelectBank orderNumber={orderNumber} orderValue={(subtotal + vat)} type="order"></SelectBank>
            </LwBankModal>
          </>
        </>

      }
    </View>


  );
};

export default OrderConfirmation;
