import {
  ADD_TO_BASKET, CLEAR_BASKET,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_RESPONSE,
  FETCH_ORDERS_AMEND,
  OrderActionTypes,
  OrderState,
  FETCH_ORDERS_AMEND_REQUEST,
} from '../types/Order';

const initialState: OrderState = {
  orders: [],
  basket: [],
  deliveryDates: [],
  deliveryCharge: 0,
  return_code: '',
  return_desc: '',
  // @ts-ignore
  return_lines: [],
  isFetchingOrders: false,
  fetchingOrdersFailed: false,
  collectDates:[],
};

const orderReducer = (
  state = initialState,
  action: OrderActionTypes,
): OrderState => {
  switch (action.type) {
    case CLEAR_BASKET:
      return {
        ...state,
        basket: [],
        return_code: '',
        return_desc: '',
        deliveryCharge: 0.00,
        // @ts-ignore
        return_lines: [],
      };
    case ADD_TO_BASKET:
      return {
        ...state,
        basket: state.basket.concat(action.payload.orderLines),
        deliveryDates: action.payload.deliveryDates,
        // @ts-ignore
        return_code: action.payload.return_code,
        // @ts-ignore
        return_desc: action.payload.return_desc,
        // @ts-ignore
        return_lines: action.payload.return_lines,
        // @ts-ignore
        deliveryCharge: action.payload.deliveryCharge,
        // @ts-ignore
        collectDates: action.payload.collectDates,
      };
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        orders: [],
        isFetchingOrders: true,
        fetchingOrdersFailed: false,
      };
    case FETCH_ORDERS_AMEND_REQUEST:
      return {
        ...state,
        orders: [...state.orders],
        isFetchingOrders: true,
        fetchingOrdersFailed: false,
      };
    case FETCH_ORDERS_RESPONSE:
      return {
        ...state,
        orders: action.payload || [],
        isFetchingOrders: false,
        fetchingOrdersFailed: false,
      };

    case FETCH_ORDERS_AMEND:
      return {
        ...state,
        orders: action.payload ? [...state.orders, ...action.payload] : [...state.orders],
        isFetchingOrders: false,
        fetchingOrdersFailed: false,
      };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        orders: [],
        isFetchingOrders: false,
        fetchingOrdersFailed: true,
      };
    default:
      return state;
  }
};

export default orderReducer;
